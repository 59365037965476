// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import MiniTitle from '../components/mini-title'
import { gray80 } from 'shared/colors'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { laptop, tablet, mobile } from 'shared/media-queries'

const Heading = () => (
	<Div display="flex" flexDirection="column" alignItems="center">
		<MiniTitle>ONLINE BID BOARD</MiniTitle>
		<Spacer height="20px" />
		<H2
			additionalCss={{
				[tablet]: { textAlign: 'center', fontSize: '36px' },
			}}
		>
			See where everything stands&mdash;and win more bids.
		</H2>
		<Spacer height="20px" />
		<Div
			fontSize="24px"
			lineHeight="1.33"
			width="75%"
			textAlign="center"
			color={gray80}
			css={{
				width: '75%',
				[laptop]: { width: '100%' },
				[mobile]: { fontSize: '16px', lineHeight: '1.25' },
			}}
		>
			See all bid invites across your entire office or division from one place.
			Know what needs to get done, who’s responsible for it and when it’s due.
			Track project files, deadlines and more during each stage of the bidding
			process.
		</Div>
	</Div>
)

export default Heading
