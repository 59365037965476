// @flow
import * as React from 'react'

import { breakpoints } from 'shared/media-queries'
import BidSmarterImage from './image'
import { cyan } from 'shared/colors'
import { InfoAndImage } from 'views/components'

type Props = {
	screenWidth: number,
}
const getWordingWidth = screenWidth => {
	if (screenWidth >= breakpoints.desktop) {
		return '486px'
	} else if (screenWidth >= breakpoints.laptop) {
		return '400px'
	}
	return undefined
}

const BidSmarter = ({ screenWidth }: Props) => (
	<InfoAndImage
		screenWidth={screenWidth}
		imageOnRight
		wideOnMobile
		image={<BidSmarterImage screenWidth={screenWidth} />}
		title="BID SMARTER"
		subtitle="Get insider info to increase your chances of winning."
		centered={screenWidth <= breakpoints.tablet}
		wordingWidth={getWordingWidth(screenWidth)}
		spacerWidths={['20px', '20px']}
		bulletItems={[
			'Track win rates so you spend your time on the right bids',
			'See how estimators on your team are performing',
			'Keep tabs on your entire business with detailed reports',
		]}
		dotColor={cyan}
		ctaLink="/bid-board/bid-analytics/"
		ctaTitle="Learn more about our bid analytics tools"
	/>
)

export default BidSmarter
