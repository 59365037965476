// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import Spacer from 'components/spacer'
import H4 from 'components/h4'
import { gray80, cyan, brandBlue } from 'shared/colors'
import { mobile } from 'shared/media-queries'

import thumb from './img/thumbnail.png'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'
import HangingQuote from 'views/components/hanging-quote'

const mobileCss = {
	[mobile]: { fontSize: '16px' },
}
const Flex = ({ children }: { children: React.Node }) => (
	<Div display="flex">{children}</Div>
)
const Heading = ({ children }: { children: React.Node }) => (
	<Div flex="1 1 0%">
		<H4 additionalCss={mobileCss}>{children}</H4>
		<Spacer height="15px" />
	</Div>
)
const Text = ({ children }: { children: React.Node }) => (
	<Div
		fontSize="18px"
		color={gray80}
		lineHeight="1.33"
		flex="1 1 0%"
		css={mobileCss}
	>
		{children}
		<Spacer height="15px" />
	</Div>
)
const Info = ({ children, ...other }: { children: React.Node }) => (
	<Div
		display="flex"
		alignItems="center"
		flex="1 1 0%"
		{...other}
		css={{
			[mobile]: { flexDirection: 'column' },
		}}
	>
		{children}
	</Div>
)
const BFHeading = () => <Heading>Bid Forwarding</Heading>
const BFText = () => (
	<Text>
		Forward invites to invites@bidboard.buildingconnected.com. This one click
		automatically creates your Bid Board and Calendar in seconds for easy
		tracking.
	</Text>
)
const BFInfo = ({ mobileSize }: { mobileSize?: boolean }) => (
	<Info>
		<Div
			fontSize="54px"
			fontWeight="700"
			color={cyan}
			lineHeight={mobileSize ? 1 : undefined}
		>
			80%
		</Div>
		<Spacer width="20px" />
		<H4
			fontSize="18px"
			maxWidth="390px"
			additionalCss={mobileCss}
			color={gray80}
		>
			decrease in the amount of manual entry with BuildingConnected
		</H4>
	</Info>
)
const GCHeading = () => <Heading>Find new GCs</Heading>
const GCText = () => (
	<Text>
		Automatically find all the GCs bidding on any job you’ve been invited to and
		track them together. Then submit your proposals to all of them in just a
		click.
	</Text>
)
const GCInfo = () => (
	<Info
		position="relative"
		cursor="pointer"
		css={{ ':hover': { textDecoration: 'underline' } }}
	>
		<WistiaVideoOverlay videoId="dlp5vx5e71" />
		<Img src={thumb} />
		<Spacer width="20px" height="10px" />
		<H4
			color={brandBlue}
			fontSize="18px"
			maxWidth="390px"
			additionalCss={mobileCss}
		>
			<HangingQuote>
				BuildingConnected gives us more opportunity to reach out to new
				clientele.
			</HangingQuote>
		</H4>
	</Info>
)

const MainInfoBottomText = ({ screenWidth }: { screenWidth: number }) =>
	screenWidth >= 1024 ? (
		<Div>
			<Flex>
				<BFHeading />
				<Spacer width="60px" />
				<GCHeading />
			</Flex>
			<Flex>
				<BFText />
				<Spacer width="60px" />
				<GCText />
			</Flex>
			<Flex>
				<BFInfo />
				<Spacer width="60px" />
				<GCInfo />
			</Flex>
		</Div>
	) : (
		<Div
			css={{
				[mobile]: { textAlign: 'center' },
			}}
		>
			<BFHeading />
			<BFText />
			<BFInfo mobileSize />
			<Spacer height="50px" />
			<GCHeading />
			<GCText />
			<GCInfo />
		</Div>
	)

export default MainInfoBottomText
