// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import Heading from './heading'
import Screenshot from './screenshot'
import BottomText from './bottom-text'
import { mobile } from 'shared/media-queries'

const MainInfo = ({ screenWidth }: { screenWidth: number }) => (
	<div>
		<Heading />
		<Div
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		<Screenshot />
		<Div
			height="50px"
			css={{
				[mobile]: { height: '30px' },
			}}
		/>
		<BottomText screenWidth={screenWidth} />
	</div>
)

export default MainInfo
