// @flow
import * as React from 'react'
import { Div, Img, Span } from 'glamorous'

import MiniTitle from '../components/mini-title'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { gray65, cyan } from 'shared/colors'
import InteractiveInfo from '../components/interactive-info'
import { laptop } from 'shared/media-queries'
import { sections, images } from './constants'
import { tablet, mobile } from 'shared/media-queries'

type Props = { screenWidth: number, selectedDotColor: string }
type State = { openIndex: number }

class AutomatedBidTracking extends React.Component<Props, State> {
	state = {
		openIndex: 0,
	}

	render() {
		const { screenWidth, selectedDotColor } = this.props

		return (
			<Div
				minHeight="625px"
				css={{
					[tablet]: { textAlign: 'center' },
				}}
			>
				<MiniTitle>AUTOMATED BID TRACKING</MiniTitle>
				<Spacer height="15px" />
				<H2
					css={{
						[tablet]: { maxWidth: '640px', margin: '0 auto' },
					}}
				>
					Save tons of time&mdash;automatically manage your Bid Board.
				</H2>
				<Spacer height="20px" />
				<Div
					fontSize="24px"
					lineHeight="1.33"
					color={gray65}
					css={{
						[mobile]: { fontSize: '16px' },
					}}
				>
					Bid Board Pro saves the average estimator{' '}
					<Span color={cyan} fontWeight="700">
						8 hours per week
					</Span>{' '}
					by eliminating manual entry and consolidating everything needed to
					manage bids in one, easy-to-access place.
				</Div>
				<Spacer height="50px" />
				<Div
					display="flex"
					css={{
						[tablet]: { display: 'block' },
					}}
				>
					<Div flex="1 1 0%">
						{screenWidth > 500 ? (
							<Img
								width="100%"
								maxHeight="476px"
								css={{
									[tablet]: { maxHeight: '312px', width: 'initial' },
								}}
								{...images[this.state.openIndex]}
							/>
						) : (
							<Img src={images[this.state.openIndex].mobileSrc} width="100%" />
						)}
					</Div>
					<Div
						css={{
							width: '120px',
							[laptop]: { width: '100px' },
							[tablet]: { height: '50px' },
							[mobile]: { height: '20px' },
						}}
					/>
					<Div flex="0 0 400px">
						<InteractiveInfo
							sections={sections}
							onInteraction={i => this.setState({ openIndex: i })}
							screenWidth={screenWidth}
							selectedDotColor={selectedDotColor}
						/>
					</Div>
				</Div>
			</Div>
		)
	}
}
export default AutomatedBidTracking
