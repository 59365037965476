// @flow
import * as React from 'react'
import { Img, Div } from 'glamorous'

import ContentWrapper from 'views/components/content-wrapper'
import cal2x from './img/cal2x.jpg'
import mobile768 from './img/mobile768.png'
import mobile1536 from './img/mobile1536.png'
import mobile2304 from './img/mobile2304.png'
import mobile640 from './img/mobile640.png'
import Wording from './wording'
import { smallerThan, laptop, mobile } from 'shared/media-queries'

const Calendar = ({ screenWidth }: { screenWidth: number }) =>
	screenWidth > 768 ? (
		<Div
			position="relative"
			height="640px"
			overflow="hidden"
			css={{
				[laptop]: { height: '614px' },
			}}
		>
			<Img
				position="absolute"
				height="100%"
				src={cal2x}
				css={{
					left: '500px',
					[smallerThan(3000)]: {
						left: '300px',
					},
					[smallerThan(2500)]: {
						left: '100px',
					},
					[smallerThan(2000)]: {
						left: '-200px',
					},
					[smallerThan(1800)]: {
						left: '-300px',
					},
					[laptop]: {
						left: '-500px',
					},
				}}
			/>
			<ContentWrapper position="relative">
				<Div position="absolute" top="100px">
					<Wording />
				</Div>
			</ContentWrapper>
		</Div>
	) : (
		<Div>
			<Img
				height="300px"
				src={mobile1536}
				srcSet={`
					${mobile2304} 2304w,
					${mobile768} 768w,
					${mobile640} 640w,
				`}
			/>
			<Div
				padding="50px 60px 0"
				css={{
					[mobile]: { padding: '40px 5% 0' },
				}}
			>
				<Wording />
			</Div>
		</Div>
	)

export default Calendar
