// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import { cyan } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const MiniTitle = ({ children }: { children: React.Node }) => (
	<Div
		fontSize="20px"
		fontWeight="600"
		letterSpacing="1px"
		textTransform="uppercase"
		color={cyan}
		css={{
			[mobile]: { fontSize: '16px' },
		}}
	>
		{children}
	</Div>
)

export default MiniTitle
