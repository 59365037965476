// @flow
import * as React from 'react'
import { Div, Img } from 'glamorous'

import Spacer from 'components/spacer'
import { gray65, gray60, brandBlue, cyan } from 'shared/colors'
import HangingQuote from 'views/components/hanging-quote'

import Triangle from 'imgs/icons/triangle.svg'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'

type Props = {
	isOpen: boolean,
	openSelf: () => mixed,
	title: string,
	wording: string,
	thumbnail?: {},
	thumbnailText?: string,
	videoId?: string,
}

const DesktopInfoSection = ({
	isOpen,
	openSelf,
	title,
	wording,
	thumbnail,
	thumbnailText,
	videoId,
}: Props) => (
	<Div>
		<Div
			display="flex"
			cursor="pointer"
			alignItems="center"
			onClick={openSelf}
			css={{
				color: isOpen ? undefined : gray60,
				':hover': { color: isOpen ? undefined : brandBlue },
			}}
		>
			<Div display="flex" transform={isOpen ? undefined : 'rotate(270deg)'}>
				<Triangle fill={cyan} />
			</Div>
			<Spacer width="10px" />
			<Div letterSpacing="1px" fontWeight="600">
				{title}
			</Div>
		</Div>
		{isOpen && (
			<Div marginLeft="25px">
				<Spacer height="10px" />
				<Div fontSize="18px" color={gray65}>
					{wording}
				</Div>
				{thumbnail && thumbnailText && videoId && (
					<Div
						display="flex"
						alignItems="center"
						paddingTop="15px"
						position="relative"
						cursor="pointer"
						css={{ ':hover': { textDecoration: 'underline' } }}
					>
						<WistiaVideoOverlay videoId={videoId} />
						<Img height="60px" {...thumbnail} />
						<Spacer width="10px" />
						<Div
							color={brandBlue}
							fontSize="16px"
							fontWeight="700"
							marginLeft="15px"
						>
							<HangingQuote>{thumbnailText}</HangingQuote>
						</Div>
						<Spacer height="15px" />
					</Div>
				)}
			</Div>
		)}
	</Div>
)

export default DesktopInfoSection
