// @flow
import * as React from 'react'
import { Div } from 'glamorous'

import MiniTitle from '../components/mini-title'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import H3 from 'components/h3'
import H4 from 'components/h4'
import { gray80 } from 'shared/colors'
import Encrypt from './img/encrypt.svg'
import Database from './img/database.svg'
import User from './img/user.svg'
import { tablet, mobile } from 'shared/media-queries'

const icons = [
	{
		icon: <Encrypt height="100%" />,
		text: 'We encrypt and monitor data for safekeeping',
	},
	{
		icon: <Database height="100%" />,
		text: 'Databases are restricted and always monitored',
	},
	{
		icon: <User height="100%" />,
		text: 'User permissions let you control who has access to what info',
	},
]

const Security = ({ screenWidth }: { screenWidth: number }) => (
	<Div
		display="flex"
		flexDirection="column"
		alignItems="center"
		css={{
			[tablet]: { marginBottom: '-30px' },
			[mobile]: { textAlign: 'center', marginBottom: '-15px' },
		}}
	>
		<MiniTitle>SECURITY AND PERMISSIONS</MiniTitle>
		<Spacer height="20px" />
		<H2>Data security is first and foremost.</H2>
		<Div
			height="30px"
			css={{
				[mobile]: { height: '10px' },
			}}
		/>
		<H3 maxWidth="1000px" color={gray80} textAlign="center">
			Data security is top priority at BuildingConnected. All information you
			submit is encrypted and securely stored in restricted databases. Unlike
			spreadsheets and white boards, you control who sees what internally with
			user permissions.
		</H3>
		<Div
			height="50px"
			css={{
				[tablet]: { height: '20px' },
			}}
		/>
		<Div
			display="flex"
			alignItems="baseline"
			width="100%"
			justifyContent="space-between"
			css={{
				[tablet]: { width: 'inherit' },
				[mobile]: { flexDirection: 'column', alignItems: 'center' },
			}}
		>
			{screenWidth < 1024 && screenWidth > 768 ? (
				<Div display="flex" maxWidth="400px">
					<Div flex="1 1 0%">
						{icons.map(({ icon }, i) => (
							<Div
								height="125px"
								display="flex"
								justifyContent="center"
								alignItems="center"
								key={i}
							>
								<Div height="80px">{icon}</Div>
							</Div>
						))}
					</Div>
					<Div flex="4 4 0%">
						{icons.map(({ text }, i) => (
							<Div
								height="125px"
								display="flex"
								alignItems="center"
								fontSize="18px"
								fontWeight="600"
								lineHeight="1.33"
								key={i}
								color={gray80}
							>
								{text}
							</Div>
						))}
					</Div>
				</Div>
			) : (
				icons.map(({ icon, text }) => (
					<Div textAlign="center" flex="1 1 0%" maxWidth="425px" key={text}>
						<Div
							height="0"
							css={{
								[mobile]: { height: '20px' },
							}}
						/>
						<Div
							css={{
								[mobile]: { height: '60px' },
							}}
						>
							{icon}
						</Div>
						<Div
							height="20px"
							css={{
								[mobile]: { height: '0' },
							}}
						/>
						<H4 fontSize="18px" padding="0 5px" color={gray80}>
							{text}
						</H4>
						<Div
							height="0"
							css={{
								[mobile]: { height: '20px' },
							}}
						/>
					</Div>
				))
			)}
		</Div>
	</Div>
)

export default Security
