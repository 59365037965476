// @flow
import * as React from 'react'
import { Img } from 'glamorous'
import analytics1440 from './img/analytics-1440.png'
import analytics14402x from './img/analytics-1440@2x.png'
import analytics14403x from './img/analytics-1440@3x.png'

import analytics1024 from './img/analytics-1024.png'
import analytics10242x from './img/analytics-1024@2x.png'
import analytics10243x from './img/analytics-1024@3x.png'

import analytics768 from './img/analytics-768.png'
import analytics7682x from './img/analytics-768@2x.png'
import analytics7683x from './img/analytics-768@3x.png'

import analyticsMobile from './img/analytics-mobile.png'
import analyticsMobile2x from './img/analytics-mobile@2x.png'
import analyticsMobile3x from './img/analytics-mobile@3x.png'

import { breakpoints } from 'shared/media-queries'

const image1440 = (
	<Img
		src={analytics1440}
		srcSet={`${analytics14402x} 2x, ${analytics14403x} 3x`}
	/>
)
const image1024 = (
	<Img
		src={analytics1024}
		srcSet={`${analytics10242x} 2x, ${analytics10243x} 3x`}
	/>
)
const image768 = (
	<Img
		src={analytics768}
		srcSet={`${analytics7682x} 2x, ${analytics7683x} 3x`}
		css={{ maxWidth: '90%' }}
	/>
)
const image320 = (
	<Img
		src={analyticsMobile}
		srcSet={`${analyticsMobile2x} 2x, ${analyticsMobile3x} 3x`}
	/>
)

const BidSmarterImage = ({ screenWidth }: { screenWidth: number }) => {
	if (screenWidth <= 375) {
		return image320
	} else if (screenWidth <= breakpoints.tablet) {
		return image768
	} else if (screenWidth < 1224) {
		return image1024
	}

	return image1440 // Default to largest in case something weird is going on
}

export default BidSmarterImage
