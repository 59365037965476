// @flow
import * as React from 'react'
import { useRef } from 'react'

import Layout from 'components/layout'
import Hero from 'views/components/hero'
import ContentWrapper from 'views/components/content-wrapper'
import Spacer from 'components/spacer'
import MainInfo from './main-info'
import Calendar from './calendar'
import LargeQuote from './large-quote'
import AutomatedBidTracking from './automated-bid-tracking'
import BidSmarter from './bid-smarter'
import Security from './security'
import Carousel from './carousel'
import Footer from 'views/components/footer'
import heroVideoWebm from './img/hero-video.webm'
import heroVideoMp4 from './img/hero-video.mp4'
import { cyan } from 'shared/colors'
import { SectionSpacer, SectionBreak } from 'views/components'
import { watchedVideoPercentage } from 'shared/wistia-methods'
import { trackMarketingEvent } from 'shared/track-marketing-event'
import useScreenWidth from 'hooks/use-screen-width'
import useScrollTo from 'hooks/use-scroll-to'

const BidBoard = () => {
	const smarterRef = useRef(null)
	useScrollTo({
		keysToRefs: { smarter: smarterRef },
	})

	const screenWidth = useScreenWidth()
	if (!screenWidth) {
		return null
	}

	return (
		<Layout
			overflow="hidden"
			title="Bid Board Pro - Bidding Software | BuildingConnected"
			description="Bid Board Pro helps subcontractors win more work by enabling them to track bid invites, stay ahead of deadlines, and get key analytics—all from one place."
		>
			<Hero
				backgroundVideo={{
					webm: heroVideoWebm,
					mp4: heroVideoMp4,
				}}
				heading="Bid Board Pro"
				subHeading="Increase your win rate by up to 25% with the first online bid board for subcontractors."
				videoId="f20ruhdpcm"
				videoCTAOnClick={() => {
					watchedVideoPercentage({
						videoId: 'f20ruhdpcm',
						percentage: 75,
						callback: () =>
							trackMarketingEvent({
								area: 'Marketing Site',
								object: 'BBPro Hype Video',
								action: 'Watched 75%',
								name: 'WATCHED_BBPRO_VIDEO_75',
							}),
					})
				}}
			/>
			<SectionSpacer />
			<ContentWrapper>
				<MainInfo screenWidth={screenWidth} />
			</ContentWrapper>
			<SectionSpacer />
			<Calendar screenWidth={screenWidth} />
			<ContentWrapper>
				{screenWidth > 768 ? <Spacer height="100px" /> : <SectionBreak />}
				<LargeQuote />
				<SectionBreak />
				<AutomatedBidTracking
					screenWidth={screenWidth}
					selectedDotColor={cyan}
				/>
				<SectionBreak />
				<BidSmarter
					screenWidth={screenWidth}
					selectedDotColor={cyan}
					forwardedRef={smarterRef}
				/>
				<SectionBreak />
				<Security screenWidth={screenWidth} />
				<SectionBreak />
				<Carousel screenWidth={screenWidth} selectedDotColor={cyan} />
			</ContentWrapper>
			<SectionSpacer />
			<Footer
				wording="Try Bid Board Pro with your team for free."
				showCreateAccount
			/>
		</Layout>
	)
}

export default BidBoard
