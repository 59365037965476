import groupingThumb from './img/grouping-thumb.jpg'
import groupingThumb2x from './img/grouping-thumb2x.jpg'
import groupingThumb3x from './img/grouping-thumb3x.jpg'
import grouping from './img/grouping.png'
import grouping2x from './img/grouping2x.png'
import grouping3x from './img/grouping3x.png'
import groupingMobile from './img/grouping-mobile.png'
import reminders from './img/reminders.png'
import reminders2x from './img/reminders2x.png'
import reminders3x from './img/reminders3x.png'
import remindersMobile from './img/reminders-mobile.png'
import contacts from './img/contacts.png'
import contacts2x from './img/contacts2x.png'
import contacts3x from './img/contacts3x.png'
import contactsMobile from './img/contacts-mobile.png'

export const sections = [
	{
		title: 'GROUP AND ASSIGN BIDS',
		wording:
			'Group the same jobs from different GCs together for easy tracking. Assign jobs to the right team member and stop duplicative work.',
		thumbnail: {
			src: groupingThumb,
			srcSet: `
				${groupingThumb2x} 2x,
				${groupingThumb3x} 3x,
			`,
		},
		thumbnailText:
			'The ease of making sure everything is assigned is just one look on the website.',
		videoId: '1dtz5hy09j',
	},
	{
		title: 'TASKS AND REMINDERS',
		wording:
			'Coming Soon: Get follow-ups, reminders for key due dates, and know when GCs have received and opened your bid.',
	},
	{
		title: 'GC CONTACT MANAGEMENT',
		wording:
			'Access all your GC company contacts from your bid board―view who works where, what they do and their contact info.',
	},
]

export const images = [
	{
		src: grouping,
		srcSet: `
			${grouping2x} 2x,
			${grouping3x} 3x,
		`,
		mobileSrc: groupingMobile,
	},
	{
		src: reminders,
		srcSet: `
			${reminders2x} 2x,
			${reminders3x} 3x,
		`,
		mobileSrc: remindersMobile,
	},
	{
		src: contacts,
		srcSet: `
			${contacts2x} 2x,
			${contacts3x} 3x,
		`,
		mobileSrc: contactsMobile,
	},
]
