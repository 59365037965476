// @flow
import * as React from 'react'
import { Img, Div } from 'glamorous'

import screenshot2416 from './2416.png'
import screenshot1304 from './1304.png'
import screenshot652 from './652.png'

const MainInfoScreenshot = () => (
	<Div position="relative">
		<Img
			width="100%"
			src={screenshot2416}
			srcSet={`
				${screenshot2416} 2416w,
				${screenshot1304} 1304w,
				${screenshot652} 652w,
			`}
		/>
	</Div>
)

export default MainInfoScreenshot
