// @flow
import * as React from 'react'
import { Img, Div } from 'glamorous'

import pic from './img/pic.png'
import pic2x from './img/pic2x.png'
import pic3x from './img/pic3x.png'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import { gray80 } from 'shared/colors'
import { mobile } from 'shared/media-queries'

const LargeQuote = () => (
	<Div textAlign="center">
		<Img
			height="140px"
			width="140px"
			src={pic}
			srcSet={`
				${pic2x} 2x,
				${pic3x} 3x,
			`}
		/>
		<Spacer height="25px" />
		<H2
			maxWidth="900px"
			margin="0 auto"
			additionalCss={{
				[mobile]: { fontSize: '20px' },
			}}
		>
			“We track hundreds of bids every month so tracking where they are, who
			they're assigned to, the due dates and where we're at was very hard, if
			not impossible before BuildingConnected.”
		</H2>
		<Spacer height="30px" />
		<Div
			fontSize="16px"
			fontWeight="700"
			letterSpacing="1px"
			color={gray80}
			css={{
				[mobile]: { fontSize: '12px', letterSpacing: '0' },
			}}
		>
			KEVIN MORTENSON, COMPLETE MILLWORK
		</Div>
	</Div>
)

export default LargeQuote
