// @flow
import * as React from 'react'

import QuotesCarousel from 'views/components/carousel/quotes-carousel'
import canterbury from './img/canterbury.png'
import canterbury2x from './img/canterbury2x.png'
import canterbury3x from './img/canterbury3x.png'
import canterburyLogo from './img/canterbury-logo.png'
import completeMillwork from './img/complete-millwork.png'
import completeMillwork2x from './img/complete-millwork2x.png'
import completeMillwork3x from './img/complete-millwork3x.png'
import completeMillworkLogo from './img/complete-millwork-logo.png'
import innovative from './img/innovative.png'
import innovative2x from './img/innovative2x.png'
import innovative3x from './img/innovative3x.png'
import innovativeLogo from './img/innovative-logo.png'
import bradley from './img/bradley.png'
import bradley2x from './img/bradley2x.png'
import bradley3x from './img/bradley3x.png'
import bradleyLogo from 'imgs/logos/bradley-concrete.png'
import WistiaVideoOverlay from 'views/components/wistia-video-overlay'

const BidBoardCarousel = ({
	screenWidth,
	selectedDotColor,
}: {
	screenWidth: number,
	selectedDotColor: string,
}) => (
	<QuotesCarousel
		screenWidth={screenWidth}
		selectedDotColor={selectedDotColor}
		quotes={[
			{
				quote: `We've tried to use other tools, but it's too much just to start tracking a bid or project...it's intimidating and you don't end up using it.`,
				name: 'Yohann Jacob',
				title: 'Project Manager, Canterbury Contractors',
				image: {
					src: canterbury,
					srcSet: `${canterbury2x} 2x, ${canterbury3x} 3x`,
				},
				video: {
					length: '1:05',
					element: <WistiaVideoOverlay videoId="v4w9iv98c9" />,
				},
				logo: {
					src: canterburyLogo,
					width: '131px',
					height: '41px',
				},
			},
			{
				quote:
					'BuildingConnected is a way for us to aggregate all information into one place―where we can see every single detail with just one click.',
				name: 'Andrew Hoxsey',
				title: 'Project Manager, Complete Millwork',
				image: {
					src: completeMillwork,
					srcSet: `${completeMillwork2x} 2x, ${completeMillwork3x} 3x`,
				},
				video: {
					length: '1:34',
					element: <WistiaVideoOverlay videoId="snufv3f3jo" />,
				},
				logo: {
					src: completeMillworkLogo,
					width: '153px',
					height: '42px',
				},
			},
			{
				quote: `Now we use BuildingConnected to export our calendar invites to Outlook, so we're hitting all our job walks and big dates.`,
				name: 'Kevin Frederik',
				title: 'VP of Business Development, Innovative Mechanical',
				image: {
					src: innovative,
					srcSet: `${innovative2x} 2x, ${innovative3x} 3x`,
				},
				video: {
					length: '1:17',
					element: <WistiaVideoOverlay videoId="qrxlgzbgm9" />,
				},
				logo: {
					src: innovativeLogo,
					width: '143px',
					height: '33px',
				},
			},
			{
				quote: `BuildingConnected makes it easy to track estimates and keep in touch with general contractors.`,
				name: 'Spencer Harrison',
				title: 'Estimator, Bradley Concrete',
				image: {
					src: bradley,
					srcSet: `${bradley2x} 2x, ${bradley3x} 3x`,
				},
				video: {
					length: '1:30',
					element: <WistiaVideoOverlay videoId="m66a48ycjx" />,
				},
				logo: {
					src: bradleyLogo,
					width: '181px',
					height: '33px',
				},
			},
		]}
	/>
)

export default BidBoardCarousel
