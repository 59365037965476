import * as React from 'react'
import { Div } from 'glamorous'

const defaults = {
	fontSize: '20px',
	lineHeight: '1.2',
	fontWeight: '700',
}

const H4 = props => {
	const css = {
		...defaults,
		...props.additionalCss,
	}
	return <Div {...props} css={css} />
}

export default H4
