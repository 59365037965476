// @flow
import * as React from 'react'
import { Div, A } from 'glamorous'

import MiniTitle from '../components/mini-title'
import { cyan, gray80, brandBlue } from 'shared/colors'
import Spacer from 'components/spacer'
import H2 from 'components/h2'
import H4 from 'components/h4'
import { mobile } from 'shared/media-queries'

const Wording = () => (
	<Div
		maxWidth="600px"
		margin="0 auto"
		css={{
			[mobile]: { textAlign: 'center' },
		}}
	>
		<MiniTitle>CALENDAR</MiniTitle>
		<Spacer height="15px" />
		<H2>One shared team calendar synced to all your devices.</H2>
		<Spacer height="15px" />
		<Div
			fontSize="24px"
			lineHeight="1.33"
			color={gray80}
			css={{
				[mobile]: { fontSize: '16px' },
			}}
		>
			What’s on your calendar today? Manage your entire team’s schedule on one
			calendar with due dates, job walks&mdash;and everything else to keep your
			team in sync.
		</Div>
		<Spacer height="15px" />
		<A
			href="/bid-board/calendar"
			fontSize="24px"
			fontWeight="700"
			color={brandBlue}
			css={{
				[mobile]: { fontSize: '16px' },
			}}
		>
			Learn More &raquo;
		</A>
		<Div
			height="15px"
			css={{
				[mobile]: { display: 'none' },
			}}
		/>
		<Div
			display="flex"
			alignItems="center"
			css={{
				[mobile]: { flexDirection: 'column' },
			}}
		>
			<Div color={cyan} fontSize="54px" fontWeight="700">
				2.3x
			</Div>
			<Spacer width="30px" />
			<H4 fontSize="18px" maxWidth="280px" color={gray80}>
				more bids submitted on time by Bid Board Pro users vs. free users
			</H4>
		</Div>
	</Div>
)

export default Wording
